/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  PageModalCloseButton,
  PageModalHeader,
  PageModal,
  PageModalBody,
  PageModalContent,
  PageModalHeadWrapper,
} from 'components/PageModal';
import { Logo } from 'components/Logo';
import { VisuallyHidden } from 'components/VisuallyHidden';
import { Stack } from 'components/Stack';
import Link from 'next/link';
import { ROUTES } from 'constants/routes';
import IconHome from 'components/icons/IconHome';
import IconOrder from 'components/icons/IconOrder';
import IconEmail from 'components/icons/IconEmail';
import { EMAIL_LINK } from 'constants/static-links';
import tw from 'twin.macro';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  id: string;
}

const menuLinks = [
  { name: 'Trang chủ', href: ROUTES.HOME, Icon: IconHome },
  { name: 'Liên hệ', href: EMAIL_LINK, Icon: IconEmail },
];

if (
  !(
    process.env.APP_STAGE === 'staging' ||
    process.env.APP_STAGE === 'production'
  )
) {
  menuLinks.splice(1, 0, {
    name: 'Theo dõi đơn hàng',
    href: ROUTES.ORDER_TRACKING,
    Icon: IconOrder,
  });
}

const footerLinks = [
  {
    name: 'Chính sách và điều khoản',
    href: ROUTES.STATIC_PAGE(),
    as: ROUTES.STATIC_PAGE('CHINH_SACH_DIEU_KHOAN'),
  },

  {
    name: 'Quy chế hoạt động',
    href: ROUTES.STATIC_PAGE(),
    as: ROUTES.STATIC_PAGE('QUY_CHE_HOAT_DONG'),
  },
  {
    name: 'Về Artzy.vn',
    href: ROUTES.STATIC_PAGE(),
    as: ROUTES.STATIC_PAGE('VE_CHUNG_TOI'),
  },
];

export const SideNavigation = ({ onClose, isOpen, id }: Props) => {
  return (
    <PageModal onClose={onClose} isOpen={isOpen}>
      <PageModalBody
        id={id}
        css={tw`bg-no-repeat bg-right-bottom`}
        style={{
          backgroundImage: `url(/img/bg.png)`,
        }}
      >
        <PageModalHeadWrapper css={tw`h-auto`}>
          <PageModalCloseButton />
          <PageModalHeader>
            <Logo css={tw`text-primary`} aria-hidden="true" />
            <VisuallyHidden>Artzy</VisuallyHidden>
          </PageModalHeader>
          <PageModalContent css={tw`pb-0 pt-2 px-0`}>
            <Stack css={tw`border-b border-gray-200 pb-4`}>
              {menuLinks.map(({ href, Icon, name }) => (
                <Link href={href} key={name}>
                  <a
                    css={tw`flex items-center px-4 py-3 cursor-pointer hover:bg-gray-100`}
                    onClick={onClose}
                  >
                    <span css={tw`text-gray-500 mr-4 flex items-center`}>
                      <Icon aria-hidden="true" className="text-gray-500" />
                    </span>
                    {name}
                  </a>
                </Link>
              ))}
            </Stack>
          </PageModalContent>
        </PageModalHeadWrapper>
        <Stack spacing={5} css={tw`flex-grow p-4 pt-5`}>
          {footerLinks.map(({ href, name, as }) => (
            <Link href={href} key={name} as={as}>
              <a css={tw`text-gray-500`} onClick={onClose}>
                {name}
              </a>
            </Link>
          ))}
        </Stack>
      </PageModalBody>
    </PageModal>
  );
};
