import { ParsedUrlQuery } from 'querystring';

export function createUTMHeaderFromQuery(
  query: ParsedUrlQuery,
): HeadersInit | undefined {
  const { utm_content, utm_medium, utm_source, utm_campaign, utm_term } = query;
  const utm = {
    utm_content,
    utm_medium,
    utm_source,
    utm_campaign,
    utm_term,
  } as { [x: string]: string };

  const value = JSON.stringify(
    Object.keys(utm)
      .filter((key) => !!utm[key])
      .reduce(
        (fin, key) => ({ ...fin, [key]: encodeURIComponent(utm[key]) }),
        {},
      ),
  );
  if (value) {
    return { 'x-utm': value };
  }

  return undefined;
}
