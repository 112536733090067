import React from 'react';
import { IconButton } from 'components/Button';
import tw, { styled } from 'twin.macro';
import { ROUTES } from 'constants/routes';
import IconCart from 'components/icons/IconCart';

const Badge = styled.span`
  ${tw`absolute text-white bg-red-500 rounded-full flex items-center justify-center whitespace-no-wrap pointer-events-none`};
  top: 3px;
  left: 16px;
  min-width: 14px;
  padding: 0 2px;
  height: 14px;
  font-size: 8px;
`;

interface Props {
  numOfItems: number;
}

export const CartButton = ({ numOfItems }: Props) => {
  return (
    <div css={tw`relative inline-flex`}>
      <IconButton
        nextHref={ROUTES.CART}
        Icon={IconCart}
        label={`Giỏ hàng với ${numOfItems} sản phẩm`}
      />
      {numOfItems > 0 && <Badge aria-hidden="true">{numOfItems}</Badge>}
    </div>
  );
};
