import React from 'react';
import createIcon from './createIcon';

const IconCart = createIcon(
  <path
    d="M21.1663 5.22222H6.48888L6.19968 3.67976C6.12576 3.28562 5.78163 3 5.38062 3H2.41667C2.18656 3 2 3.18656 2 3.41667V3.69444C2 3.92455 2.18656 4.11111 2.41667 4.11111H5.15L7.57801 17.0606C7.21892 17.4553 6.99999 17.9798 6.99999 18.5555C6.99999 19.7828 7.99492 20.7778 9.22221 20.7778C10.4495 20.7778 11.4444 19.7828 11.4444 18.5555C11.4449 18.1654 11.342 17.782 11.1464 17.4444H16.1869C15.9913 17.782 15.8885 18.1654 15.8889 18.5555C15.8889 19.7828 16.8838 20.7778 18.1111 20.7778C19.3384 20.7778 20.3333 19.7828 20.3333 18.5555C20.3333 17.9308 20.0752 17.3666 19.6601 16.9629C19.5702 16.6073 19.2486 16.3333 18.8516 16.3333H8.57221L8.15555 14.1111H19.6618C20.0565 14.1111 20.3971 13.8341 20.4776 13.4477L21.9822 6.22552C22.09 5.70802 21.6949 5.22222 21.1663 5.22222ZM10.3333 18.5555C10.3333 19.1682 9.83488 19.6666 9.22221 19.6666C8.60954 19.6666 8.1111 19.1682 8.1111 18.5555C8.1111 17.9429 8.60954 17.4444 9.22221 17.4444C9.83488 17.4444 10.3333 17.9429 10.3333 18.5555ZM18.1111 19.6666C17.4984 19.6666 17 19.1682 17 18.5555C17 17.9429 17.4984 17.4444 18.1111 17.4444C18.7238 17.4444 19.2222 17.9429 19.2222 18.5555C19.2222 19.1682 18.7238 19.6666 18.1111 19.6666ZM19.4359 13H7.94721L6.69722 6.33333H20.8248L19.4359 13Z"
    fill="currentColor"
  />,
);

export default IconCart;
