import React from 'react';
import Link from 'next/link';
import { Logo } from './Logo';
import { VisuallyHidden } from './VisuallyHidden';
import { CartButton } from './CartButton';
import { ROUTES } from 'constants/routes';
import { IconButton } from './Button';
import { SideNavigation } from './SideNavigation';
import { useCartContext } from 'context/cart-context';
import IconMenu from './icons/IconMenu';
import { useDisclosure } from 'hooks/useDisclosure';
import tw from 'twin.macro';

export const Header = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { cartItems } = useCartContext();

  return (
    <header css={tw`bg-white border-gray-200 border-b`}>
      <div css={tw`flex items-center h-14 px-2`}>
        <div css={tw`w-1/3`}>
          <IconButton
            Icon={IconMenu}
            label="Mở menu"
            aria-controls="side-navigation"
            onClick={onOpen}
          />
        </div>
        <div css={tw`w-1/3 flex justify-center`}>
          <Link href={ROUTES.HOME}>
            <a css={tw`text-secondary font-semibold text-xl`}>
              <VisuallyHidden>Artzy</VisuallyHidden>
              <Logo aria-hidden="true" css={tw`text-primary`} />
            </a>
          </Link>
        </div>
        <div css={tw`w-1/3 text-right`}>
          <CartButton numOfItems={cartItems.length} />
        </div>
      </div>
      <SideNavigation isOpen={isOpen} onClose={onClose} id="side-navigation" />
    </header>
  );
};
