import React from 'react';
import createIcon from './createIcon';

const IconEmail = createIcon(
  <path
    d="M21.6211 8.95312C21.7734 8.83203 22 8.94531 22 9.13672V17.125C22 18.1602 21.1602 19 20.125 19H3.875C2.83984 19 2 18.1602 2 17.125V9.14062C2 8.94531 2.22266 8.83594 2.37891 8.95703C3.25391 9.63672 4.41406 10.5 8.39844 13.3945C9.22266 13.9961 10.6133 15.2617 12 15.2539C13.3945 15.2656 14.8125 13.9727 15.6055 13.3945C19.5898 10.5 20.7461 9.63281 21.6211 8.95312ZM12 14C12.9062 14.0156 14.2109 12.8594 14.8672 12.3828C20.0508 8.62109 20.4453 8.29297 21.6406 7.35547C21.8672 7.17969 22 6.90625 22 6.61719V5.875C22 4.83984 21.1602 4 20.125 4H3.875C2.83984 4 2 4.83984 2 5.875V6.61719C2 6.90625 2.13281 7.17578 2.35938 7.35547C3.55469 8.28906 3.94922 8.62109 9.13281 12.3828C9.78906 12.8594 11.0938 14.0156 12 14Z"
    fill="currentColor"
  />,
);

export default IconEmail;
