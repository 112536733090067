import { useState, useCallback } from 'react';
import { createContext } from 'utils/react-helpers';

interface ContextValues {
  setFiles: (files: File[]) => void;
  reset: () => void;
  files: File[];
}

const [Provider, usePhotoUploadContext] = createContext<ContextValues>();

const PhotoUploadContextProvider: React.FC = ({ children }) => {
  const [files, setFiles] = useState<File[]>([]);

  const reset = useCallback(() => {
    setFiles([]);
  }, []);

  return (
    <Provider
      value={{
        setFiles,
        files,
        reset,
      }}
    >
      {children}
    </Provider>
  );
};

export { usePhotoUploadContext, PhotoUploadContextProvider };
