export function scrollToNode(
  selector: string,
  options?: { delay?: number; adjustPosition?: number },
) {
  const { delay = 0, adjustPosition = 0 } = options || {};

  setTimeout(() => {
    const node = document.querySelector(selector) as HTMLElement;
    if (node) {
      window.scrollTo({
        top: node.offsetTop + adjustPosition,
        behavior: 'smooth',
      });
    }
  }, delay);
}

export function getUA() {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export function isAndroid(ua: string) {
  return /(Android)/i.test(ua) || false;
}

export function isInApp(ua: string) {
  return (
    /(\bFB[\w_]+\/(Messenger|MESSENGER))|(\bFB[\w_]+\/)|(\bTwitter)|(\bInstagram)|(\bLine\/)/i.test(
      ua,
    ) || false
  );
}

// forceOpenInBrowser force inapp browser like fb/messenger open url in browser
export function forceOpenInBrowser() {
  const ua = getUA();
  if (isInApp(ua) && isAndroid(ua)) {
    const currentHref = window.location.href.replace(/https?:\/\//, '');
    window.location.href = `intent:${currentHref}#Intent;scheme=http;action=android.intent.action.VIEW;end;`;
  }
}
