import React from 'react';
import createIcon from './createIcon';

const IconHome = createIcon(
  <path
    d="M19.6385 13.7089V19.1827C19.6385 19.6908 19.2298 20.0995 18.7218 20.0995H14.2908C14.0387 20.0995 13.8324 19.8932 13.8324 19.6411V15.3629C13.8324 15.1108 13.6261 14.9045 13.374 14.9045H10.6237C10.3716 14.9045 10.1653 15.1108 10.1653 15.3629V19.6411C10.1653 19.8932 9.95907 20.0995 9.70696 20.0995H5.27595C4.76791 20.0995 4.35919 19.6908 4.35919 19.1827V13.7089C4.35919 13.5714 4.42031 13.4415 4.52726 13.3537L11.7086 7.44056C11.8766 7.30305 12.1211 7.30305 12.293 7.44056L19.4743 13.3537C19.5774 13.4415 19.6385 13.5714 19.6385 13.7089ZM22.8319 11.3826L19.6385 8.75076V3.46029C19.6385 3.20818 19.4323 3.00191 19.1802 3.00191H17.041C16.7889 3.00191 16.5827 3.20818 16.5827 3.46029V6.23349L13.1639 3.41827C12.4878 2.86058 11.5099 2.86058 10.8338 3.41827L1.16581 11.3826C0.970996 11.5431 0.944257 11.8334 1.10469 12.0282L2.07875 13.2123C2.23918 13.4071 2.52949 13.4339 2.7243 13.2734L11.7086 5.87443C11.8766 5.73691 12.1211 5.73691 12.293 5.87443L21.2772 13.2734C21.4721 13.4339 21.7624 13.4071 21.9228 13.2123L22.8969 12.0282C23.0573 11.8295 23.0267 11.5431 22.8319 11.3826Z"
    fill="currentColor"
  />,
);

export default IconHome;
