import '../styles/index.css';
import App, { AppContext } from 'next/app';
import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import { PhotoUploadContextProvider } from 'context/photo-upload-context';
import { CartContextProvider } from 'context/cart-context';
import { Header } from 'components/Header';
import Router from 'next/router';
import { GA_TRACKING_ID, pageView } from 'libs/gtag';
import injectAppBuildContext from 'scripts/inject-app-build-context';
import { DefaultSeo } from 'next-seo';
import getConfig from 'next/config';
import Head from 'next/head';
import { captureException } from 'libs/logger';
import { NProgressHandler } from 'components/NProgressHandler';
import { setDefaultHeaders } from 'libs/fetcher';
import { createUTMHeaderFromQuery } from 'utils/request';
import { forceOpenInBrowser } from 'utils/dom';
import initSentry from 'libs/sentry';

injectAppBuildContext();

// Intersection Observer is the API used to determine if an element is inside the viewport or not.
// Browser support is really good - With Safari adding support in 12.1, all major browsers now support Intersection Observers natively.
// Add the polyfill, so it doesn't break on older versions of iOS and IE11.
async function loadIntersectionObserver() {
  try {
    if (typeof window.IntersectionObserver === 'undefined') {
      // @ts-ignore: missing type definition
      await import('intersection-observer');
    }
  } catch (error) {
    captureException(error);
  }
}

// Make smooth scroll available for mobile devices
async function loadSmootnScroll() {
  try {
    // @ts-ignore: missing type definition
    const smoothscroll = await import('smoothscroll-polyfill');
    smoothscroll.polyfill();
  } catch (error) {
    captureException(error);
  }
}

const GlobalStyled = createGlobalStyle`
  body{
    ${tw`bg-gray-200 sm:bg-gray-350`}
  }
`;

Router.events.on('routeChangeComplete', (url) => {
  if (GA_TRACKING_ID) {
    pageView(url);
  }
});

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const {
    serverRuntimeConfig: { rootDir },
  } = getConfig();
  const distDir = `${rootDir}/.next`;

  initSentry(
    process.env.NEXT_PUBLIC_SENTRY_DSN,
    process.env.GITHUB_COMMIT_SHA ||
      process.env.TAG_CURRENT_VERSION ||
      'production',
    distDir,
  );
}

class MyApp extends App<{
  origin: string;
  utmHeader?: HeadersInit;
}> {
  static async getInitialProps(appContext: AppContext) {
    const {
      ctx: { req, query },
    } = appContext;

    const origin = req?.headers.host || 'artzy.vn';

    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      origin,
      utmHeader: createUTMHeaderFromQuery(query),
    };
  }

  componentDidMount() {
    loadIntersectionObserver();
    loadSmootnScroll();

    forceOpenInBrowser();

    const { utmHeader } = this.props;
    if (utmHeader) {
      setDefaultHeaders(utmHeader);
    }
  }

  render() {
    const { Component, pageProps, origin } = this.props;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no"
          />
        </Head>
        <DefaultSeo
          title="Khung ảnh thông minh, không cần đinh | Artzy"
          openGraph={{
            type: 'website',
            locale: 'vi_VN',
            url: `https://${origin}`,
            site_name: 'Artzy',
            title: 'Khung ảnh thông minh, không cần đinh | Artzy',
            images: [
              {
                width: 1200,
                height: 628,
                url: `https://${origin}/og-img.png`,
              },
            ],
          }}
          twitter={{
            handle: '@artzy',
            site: '@artzy',
            cardType: 'summary_large_image',
          }}
          description="Khung ảnh dán tường artzy, thế hệ khung ảnh mới không cần dùng đinh tất cả những gì bạn cần làm chỉ là tải ảnh lên và dán trực tiếp lên tường"
        />
        <GlobalStyled />
        <CartContextProvider>
          <main css={tw`sm:container sm:min-h-screen bg-gray-200`}>
            <Header />
            <PhotoUploadContextProvider>
              <Component {...pageProps} />
            </PhotoUploadContextProvider>
          </main>
        </CartContextProvider>
        <NProgressHandler />
      </>
    );
  }
}
export default MyApp;
