import React from 'react';
import createIcon from './createIcon';

const IconOrder = createIcon(
  <path
    d="M22.3889 13H19.3333V16.6667L18.1111 15.8531L16.8889 16.6667V13H13.8333C13.4972 13 13.2222 13.275 13.2222 13.6111V20.9444C13.2222 21.2806 13.4972 21.5556 13.8333 21.5556H22.3889C22.725 21.5556 23 21.2806 23 20.9444V13.6111C23 13.275 22.725 13 22.3889 13ZM7.72222 10.5556H16.2778C16.6139 10.5556 16.8889 10.2806 16.8889 9.94444V2.61111C16.8889 2.275 16.6139 2 16.2778 2H13.2222V5.66667L12 4.85312L10.7778 5.66667V2H7.72222C7.38611 2 7.11111 2.275 7.11111 2.61111V9.94444C7.11111 10.2806 7.38611 10.5556 7.72222 10.5556ZM10.1667 13H7.11111V16.6667L5.88889 15.8531L4.66667 16.6667V13H1.61111C1.275 13 1 13.275 1 13.6111V20.9444C1 21.2806 1.275 21.5556 1.61111 21.5556H10.1667C10.5028 21.5556 10.7778 21.2806 10.7778 20.9444V13.6111C10.7778 13.275 10.5028 13 10.1667 13Z"
    fill="currentColor"
  />,
);

export default IconOrder;
