import React from 'react';
import createIcon from './createIcon';

const IconMenu = createIcon(
  <path
    d="M21.7321 5.60714H2.26786C2.19682 5.60714 2.12869 5.57892 2.07845 5.52869C2.02822 5.47846 2 5.41033 2 5.33929V4.26786C2 4.19682 2.02822 4.12869 2.07845 4.07845C2.12869 4.02822 2.19682 4 2.26786 4H21.7321C21.8032 4 21.8713 4.02822 21.9215 4.07845C21.9718 4.12869 22 4.19682 22 4.26786V5.33929C22 5.41033 21.9718 5.47846 21.9215 5.52869C21.8713 5.57892 21.8032 5.60714 21.7321 5.60714ZM21.7321 12.75H2.26786C2.19682 12.75 2.12869 12.7218 2.07845 12.6715C2.02822 12.6213 2 12.5532 2 12.4821V11.4107C2 11.3397 2.02822 11.2715 2.07845 11.2213C2.12869 11.1711 2.19682 11.1429 2.26786 11.1429H21.7321C21.8032 11.1429 21.8713 11.1711 21.9215 11.2213C21.9718 11.2715 22 11.3397 22 11.4107V12.4821C22 12.5532 21.9718 12.6213 21.9215 12.6715C21.8713 12.7218 21.8032 12.75 21.7321 12.75ZM21.7321 19.8929H2.26786C2.19682 19.8929 2.12869 19.8646 2.07845 19.8144C2.02822 19.7642 2 19.696 2 19.625V18.5536C2 18.4825 2.02822 18.4144 2.07845 18.3642C2.12869 18.3139 2.19682 18.2857 2.26786 18.2857H21.7321C21.8032 18.2857 21.8713 18.3139 21.9215 18.3642C21.9718 18.4144 22 18.4825 22 18.5536V19.625C22 19.696 21.9718 19.7642 21.9215 19.8144C21.8713 19.8646 21.8032 19.8929 21.7321 19.8929Z"
    fill="currentColor"
  />,
);

export default IconMenu;
