import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

export default function initSentry(
  dsn: string,
  release: string,
  rootDir: string = '',
) {
  const distDir = `${rootDir}/.next`;
  Sentry.init({
    enabled: true,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame?.filename?.replace?.(distDir, 'app:///_next');

          return frame;
        },
      }),
    ],
    dsn,
    release,
    ignoreErrors: [
      'top.GLOBALS',
      'No error message',
      /ignore this error/i,
      'Đã mất kết nối mạng',
    ],
    // @ts-ignore sentry node doesnt have this option howerver browser does
    denyUrls: [
      // Facebook flakiness
      /.+facebook.com\/signals\/iwl.js.+/,
      /.+graph\.facebook\.com/i,
      /.+connect\.facebook\.net\/.+/i,
      // tiktok
      /.+analytics.tiktok.com\/i18n\/pixel\/sdk.js.+/i,
      // yandex
      /.+mc.yandex.ru\/metrika\/tag.js.+/i,
      // gtag
      /.+googletagmanager.com\/.+/i,
      /.+google-analytics.com\/analytics.js/i,
      /.+stats.g.doubleclick.net\/.+/i,
    ],
    beforeBreadcrumb(breadcrum) {
      const blacklistRegex = /.+.yandex.ru|.+doubleclick\.net/gi;
      const { category = '', data = {} } = breadcrum;
      if (
        category?.toLowerCase() === 'xhr' &&
        data?.url?.match(blacklistRegex)
      ) {
        return null;
      }

      return breadcrum;
    },
  });
}
