declare global {
  interface Window {
    ARTZY: any;
  }
}

export default function injectAppBuildContext() {
  if (typeof window === 'object') {
    window.ARTZY = {
      name: process.env.APP_NAME,
      stage: process.env.APP_STAGE,
      version: process.env.PACKAGE_CURRENT_VERSION,
    };

    if (process.env.PR_LINK) {
      window.ARTZY.pr = process.env.PR_LINK;
    } else if (process.env.APP_STAGE !== 'development') {
      window.ARTZY.version = process.env.TAG_CURRENT_VERSION;
    }
  }
}
